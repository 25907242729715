import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Iframe from 'react-iframe';
import liff from "@line/liff";
import ModalWaiting from "../Components/ModalWaiting";
import GetImage from "../Components/GetImage";

import "./App.css";

function LIFFHome() {
  const liffId = "2006524571-Nnb0D0l0";
  const [userName, setUserName] = useState("");
  const [userID, setUserID] = useState("");
  const [params, setParams] = useState("");
  const [url, setURL] = useState("");
  const [modalshow, setModalshow] = useState(true);
  const [code, setCode] = useState(GetImage("Calender"));

	const queryString = decodeURIComponent(window.location.search).replace(
	  '?liff.state=',
	  ''
	)
	const queries = new URLSearchParams(queryString)

  useEffect(() => {
    liff
      .init({
        liffId: "2006524571-Nnb0D0l0",
      })
      .then(() => {
        liff
          .getProfile()
          .then((profile) => {
            setUserName(profile.displayName);
            setUserID(profile.userId);
            setParams(queries.get('params'));
          })
          .catch((err) => alert(err));
      })
		  .catch((err) => alert(err));
  }, []);
  //<iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FTokyo&showPrint=0&showTz=0" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe>
  //<iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FTokyo&showPrint=0&src=NDU2NzI5MjU2OTA1NTBjZDJhOGI0MDI0ZjYwMTBjNTExZjZkOGFiMGMwNjYzNThhZTIxMGM5OGI5ODgyMWI4M0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23F09300&color=%230B8043" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe>
  //<iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FTokyo&showPrint=0&showTz=0&src=NDU2NzI5MjU2OTA1NTBjZDJhOGI0MDI0ZjYwMTBjNTExZjZkOGFiMGMwNjYzNThhZTIxMGM5OGI5ODgyMWI4M0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23F09300&color=%230B8043" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe>
  //<iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FTokyo&showPrint=0&src=NDU2NzI5MjU2OTA1NTBjZDJhOGI0MDI0ZjYwMTBjNTExZjZkOGFiMGMwNjYzNThhZTIxMGM5OGI5ODgyMWI4M0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23F09300" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe>
  //params=management
  //params=richmenuchange
  //<iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FTokyo&showPrint=0&showTz=0&src=MGU5ZjE5ZTdmNDg2ODM2NzcyY2M5ZWYxN2Y0ZjJmMzEzNGFiZDdmMDZlNjRiMzkwOTg1MTc4M2VlNDBhNDIyZEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%233F51B5" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe>
   useEffect(() => {
   	  if(userID.length > 0 && params.length > 0 && userName.length > 0){
   	  	if(params==="calender"){
   	  		//setURL(encodeURI("https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FTokyo&showPrint=0&showTz=0&src=NDU2NzI5MjU2OTA1NTBjZDJhOGI0MDI0ZjYwMTBjNTExZjZkOGFiMGMwNjYzNThhZTIxMGM5OGI5ODgyMWI4M0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23F09300&color=%230B8043"));
   	  		//setURL(encodeURI("https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FTokyo&showPrint=0&showTz=0&src=NDU2NzI5MjU2OTA1NTBjZDJhOGI0MDI0ZjYwMTBjNTExZjZkOGFiMGMwNjYzNThhZTIxMGM5OGI5ODgyMWI4M0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23F09300"));
			setURL("https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FTokyo&showPrint=0&showTz=0&src=MGU5ZjE5ZTdmNDg2ODM2NzcyY2M5ZWYxN2Y0ZjJmMzEzNGFiZDdmMDZlNjRiMzkwOTg1MTc4M2VlNDBhNDIyZEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%233F51B5");
   	  	}else{
   	  		setURL(encodeURI(`https://script.google.com/macros/s/AKfycbwOKFkLBTUyIfPddRWNEQMbOpTQOnqXN8yj6MxpvmKNsBf3sx_llG57pTktaV4pO9Yi/exec?userID=${userID}&userName=${userName}&params=${params}`));
   	  	}
   	  }
   }, [userID,params,userName]);
    const loaded = () => {
		setModalshow(false);
    }
  return (
  		<>
		<style jsx>{`
			.grid-area {
			  display: grid;
			  height: 100vh;                /* grid全体の高さを設定 */
			  grid-template-rows: 90% 10%;  /* gridの割合が8:2になるよう2行用意 */
			  grid-template-columns: 100%;  /* 横幅いっぱいに1列用意 */
			}
			.top-area {
			  grid-row: 1;
			  grid-column: 1;
			}
			.bottom-area {
			  grid-row: 2;
			  grid-column: 1;
			}
			.cardClass{
				height:80vh !important;
			}
		`}</style>
		<div className="grid-area">
			<div className="top-area">
				{params !=="calender" && url.length > 0 && <Iframe url={url}
				        width="100%"
				        height="100%"
				        onLoad={loaded}
				        />}
	 			{params ==="calender" &&<Card className="cardClass">
				      <Card.Img variant="top" src={code} />
				      <Card.Body>
							{url.length > 0 && <Iframe url={url}
							        width="100%"
							        height="100%"
							        onLoad={loaded}
							        />}
				      </Card.Body>
			    </Card>}
		    </div>
		    <div className="bottom-area mt-2">
				<div className="d-flex justify-content-end">
				  <Button className="me-2" variant="outline-primary" onClick={()=>{liff.closeWindow();}}>閉じる</Button >
				</div>
			</div>
	    </div>
        <ModalWaiting show={modalshow} />
        </>
  );
}

/***
				        display="block"
				        position="absolute"
		   		        <ModalWaiting show={modalshow} />
  return (
    <div className="App">
      <p>{params}</p>
      <p>{userName}</p>
      <p>{userID}</p>
    </div>
  );
  
***/
export default LIFFHome;
