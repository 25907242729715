import { useState,useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Iframe from 'react-iframe';
import ModalWaiting from "../Components/ModalWaiting"
import GetImage from "../Components/GetImage";
import "./App.css";

function Calendar() {
  const [modalshow, setModalshow] = useState(true);
  const [code, setCode] = useState(GetImage("Calender"));
  const [url, setURL] = useState("");

   useEffect(() => {
		setURL("https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FTokyo&showPrint=0&showTz=0&src=MGU5ZjE5ZTdmNDg2ODM2NzcyY2M5ZWYxN2Y0ZjJmMzEzNGFiZDdmMDZlNjRiMzkwOTg1MTc4M2VlNDBhNDIyZEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%233F51B5");
   }, []);
    const loaded = () => {
		setModalshow(false);
    }
  return (
  		<>
		<style jsx>{`
			.grid-area {
			  display: grid;
			  height: 100vh;                /* grid全体の高さを設定 */
			  grid-template-rows: 90% 10%;  /* gridの割合が8:2になるよう2行用意 */
			  grid-template-columns: 100%;  /* 横幅いっぱいに1列用意 */
			}
			.top-area {
			  grid-row: 1;
			  grid-column: 1;
			}
			.bottom-area {
			  grid-row: 2;
			  grid-column: 1;
			}
			.cardClass{
				height:80vh !important;
			}
		`}</style>
		  <Container>
			<Row className="justify-content-md-center">
			<Col md={3}></Col>
			<Col xs={12} md={6}>
	 			<Card className="cardClass">
				      <Card.Img variant="top" src={code} />
				      <Card.Body>
							{url.length > 0 && <Iframe url={url}
							        width="100%"
							        height="100%"
							        onLoad={loaded}
							        />}
				      </Card.Body>
			    </Card>
			</Col>
			<Col md={3}></Col>
			</Row>
	      </Container>
        <ModalWaiting show={modalshow} />
        </>
  );
}

export default Calendar;
