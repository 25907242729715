import { useState } from 'react';

import { Routes, Route, Link, BrowserRouter } from 'react-router-dom';
import LIFFHome from './Page/LIFFHome';
import About from './Page/About';
import Video from './Page/Video';
import FontShow from './Page/FontShow';
//import FontShow2 from './Page/FontShow2';
import FontShow3 from './Page/FontShow3';
import Calendar from './Page/Calendar';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LIFFHome />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/about" element={<About />} />
          <Route path="/video" element={<Video />} />
          <Route path="/fontshow" element={<FontShow />} />
          <Route path="/font" element={<FontShow3 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
