import { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Iframe from 'react-iframe';
import {isMobile } from 'react-device-detect';
import ModalWaiting from "../Components/ModalWaiting"

export default function Video() {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const routepath = query.get('path');
  const [url, setURL] = useState("");
  const [modalshow, setModalshow] = useState(true);
  const [fontshow, setFontShow] = useState(false);
  const [errorshow, setErrorshow] = useState(false);

//https://learning.smarteasypos.net/video?path=effect
//https://drive.google.com/file/d/12J9DtoZVuXGjv2NOlFajrDywNnO4hco0/view?usp=drive_link
//https://drive.google.com/file/d/12J9DtoZVuXGjv2NOlFajrDywNnO4hco0/preview?usp=drivesdk
//https://drive.google.com/file/d/10s-BpzOdirsHmdix70zHYa3Gyng_hEKs/view?usp=sharing
//https://drive.google.com/file/d/1T07GmR5nXKSS4XLhuoTif_kRWRfcEY6O/view?usp=drive_link
//https://drive.google.com/file/d/1WMA_VC4hjSwTLCZod8sEZ6djphhQSBTA/view?usp=drive_link
//https://drive.google.com/file/d/1XJfTAZUQq8GoDP1pNTU6NNKx73TWNV17/view?usp=drive_link
//https://drive.google.com/file/d/1FLXI9DMWFq1sVyJrlZmbx48cF3FyBgjj/view?usp=drive_link
//https://drive.google.com/file/d/1FNWSuaVlIu4IuUJwK25flCUyKKH8vaUC/view?usp=drive_link

  const driveIDList = [{type:"overview",browser:"12J9DtoZVuXGjv2NOlFajrDywNnO4hco0",mobile:"1es2p7yuQBIj43waGLxJFsuimTVVxJjSs"},//Public
					   {type:"features",browser:"1fQ3SlD4NiysYADX_WPNmWpMmQiH1HvZd",mobile:"1ChrIprylFPQR_IuuQ9dOp3j8AOotBOke"},
					   {type:"pcpreparation",browser:"16_GKXGyBi0ggPdP1FG2a6P16a_HS5hvj",mobile:"16aFxpve6CAk70YGX4GHkIa3gNGTzRABk"},
					   {type:"coursepreparation",browser:"19w6aIN8P8MLZOOROA9XEdfBj17HLOnR_",mobile:"18XdTBl6rAJBjDscFk2fbvwWd0rRNqHzP"},
					   {type:"schoolrules",browser:"1GCVj_0px_fxXh9XetGF9MkLX3ULPXWBR",mobile:"18Z-oel56fmeM6BVCxuj6SFpT7LlvHVZu"},
					   {type:"pcbegginers",browser:"1vsxAnNyRTJ4VoT13MbvGAf9bIZRdFgXl",mobile:"18qK2_aS_B5z4BAZNvg2r7FUqwbkUr1Uv"},
					   {type:"canvabasic",browser:"1DAhzr9c5D8V2itKPdbqVG8YFaWHBYIpZ",mobile:"191vzGm8CM1Jtmfzc3GI9mwegVWU32AwE"},
					   {type:"canvavideo",browser:"1Dn8rhngxG6IzQC6rUKfdo5PY4oVsJXEk",mobile:"198VFnNry2w0OFiwITRBRoecI7Oqr4q11"},
					   {type:"canvaweb",browser:"1FMuxPE7kf8RV4PDULmzZKlYEY5sEECV5",mobile:"19Czh2tAt34xfJ6ZxIQouQgfW4DE5Of-4"},
					   {type:"personal",browser:"1FQwwis99JlXzhP9dR8UBisPy1PmyGntS",mobile:"19Fr5eDoLacvBXJ3fNcKrdBHWNBxpOuVX"},
					   {type:"canvawebwork",browser:"1CjOhkfQHnb-m_zYXxHT5AGsP-Y9Kbhrt",mobile:"1-5bbMvBxPCjNY05KpzmnzQmMjs7w16Er"},
					   {type:"canvavideowork",browser:"1CH-nOR3Ddv46gd7Vklj3o79TeeRf6KIZ",mobile:"1-AwIIbV7Bk4P8IYofsql8bHSGecTSnOZ"},
					   {type:"whatscanva",browser:"1uHFQntLAiE0qF8nYZqY2PnWIakmOFpIl",mobile:"1ifLpsFON_-N35KPPgolVHKQYcZ7SifLi"},
					   {type:"access",browser:"1BkxllYoyj9d-tsH3BWQLp8ORaRYKuYd-",mobile:"19ruOoOJfrZ89HBSWGkO72mRj9kx3ReBO"},
					   {type:"contact",browser:"1gSFAlfApV6xw5Px5S9nlyTp_fZzZPzLd",mobile:"19sBZo0kS4lZaCxJxLc1G029RpkOm6xK5"},
					   {type:"baby1",browser:"16LPV8ZNBK6LzzGPcPGCVk0pYff64Zp_l",mobile:"1ANHlhGwF9qEhGIyTqsWtm_2STv4sfpMb"},
					   {type:"softbank",browser:"1WZAAJCKEPADCjoQaCznUY7TZNud8R9Jq",mobile:"1ATor9vykEA1MSa5nz4hAVroeRplMCPBv"},
					   {type:"aquarium",browser:"16N7B_574gM_nIQ2ydKrbm-HV4oTx6Fjt",mobile:"1Aw2r9YM0FFFl9S8M4Q59L7jI5gIbYiZ-"},
					   {type:"yamakasa",browser:"14ALAQYU4itc7EQ54RXtoHkr5T70eYLYu",mobile:"1B8YLZDoWFM4LQexKXFi_6Ycez49hUGkY"},
					   {type:"dazaifuasuka",browser:"14IHC9sBc_o2VkVua0QACNgiPrnmiEDbP",mobile:"1-Hu6YAGTyPvG6mhJSJKbMUJ4IY8ENa3k"},
					   {type:"dazaifunara",browser:"1tDxZcggje8KBJMKKhhgFo5XiD1LBuNFQ",mobile:"1CV5hD7ff2D-2CpqPfJd8azw8JiExuoZf"},
					   {type:"dazaifubudda",browser:"151G0LZXU4JN7gMy8La62GYsldX7Hw3x2",mobile:"1-RVidDZWG1osw_PZqHB2FAT7DvmcClrM"},
					   {type:"dazaifutenmangu",browser:"1tEKITByaiZnBCXne95chcJ2tY35ngwT9",mobile:"1-fT23nrp0R1fHb7acBk-mNvH0u3awkKs"},
					   {type:"afterdeath",browser:"1tISZXeTovJSu0MrMnra6PYjrADm2wH_y",mobile:"1-cTwofax0bxM70BxIKOSd5XIaNMoJwwD"},
					   {type:"relatedto",browser:"143f2kyPJDiwk02qa2-o77e9w1v2V6NAA",mobile:"1-g6QeFjgAlioBPyu-LHe3XmettNFhuFp"},
					   {type:"studentwork",browser:"1NYZTWz2BqLjXSHCGUXMCkS5tu_IITTvV",mobile:"1-zf9FRsAS_4vgY3_veSJ-gWw_gdPQ58m"},
					   {type:"jamboreemickey",browser:"1AeA3v08bCklWx8dtt-CX7N8mv-aEYwPL",mobile:"19l25IqINEy40bFvekESzwIsLh_BbPYj1"},
					   {type:"shichigosan",browser:"1JNhZMi6pz1Vt5tsx4i0y2T8Kcw-M87Lj",mobile:"1JLyfI9yiqm86mOrQn1jFVicsx1cQQvb8"},
					   {type:"automnleaves",browser:"1JhEs7sGMVA-7D8ZcT0epklELmQfF7U-P",mobile:"1JiRMZJrN0L3bpODa54aArPhCQrihoBZy"},
					   {type:"youtubecm",browser:"mgaaVxF085E?si=lWO1QvVP8Psv6rkq"},//src="https://www.youtube.com/embed/mgaaVxF085E?si=lWO1QvVP8Psv6rkq"
					   {type:"youtubecm2",browser:"CY9Ck8xlUv4?si=9dF1DdyzpYQCBbb_"},//src="https://www.youtube.com/embed/CY9Ck8xlUv4?si=9dF1DdyzpYQCBbb_"
					   {type:"youtubecm3",browser:"msrdavnDAfo?si=adyi-Hif572WkMJt"},//src="https://www.youtube.com/embed/msrdavnDAfo?si=adyi-Hif572WkMJt"
					   {type:"youtubecm4",browser:"xI2D6i1uyXg?si=AfAXzGAyHZipBS9q"},//src="https://www.youtube.com/embed/xI2D6i1uyXg?si=AfAXzGAyHZipBS9q"
					   {type:"youtubecm5",browser:"wBYQTzdfedk?si=crt-wSgFUU8mOblJ"},//src="https://www.youtube.com/embed/wBYQTzdfedk?si=crt-wSgFUU8mOblJ"
					   {type:"youtubeatm",browser:"yoMsZCwr4dg?si=oWxp8t360NJmiTRm"},//yoMsZCwr4dg?si=oWxp8t360NJmiTRm
					   {type:"learningmethod",browser:"1753XwbkyL9uIGrW44jbRP5gmtBd5oxck",mobile:"175zcyHSUEuvTf2Y6SNtdhdqAFnGX-pyK"},//学習サイト
					   {type:"canvafirststep",browser:"1Q_9CQazMxV9bVfTY971GNNCE8BJISzZR",mobile:"17dZlXWXzCn_tpAma16u7uyVy8hn-o594"},
					   {type:"canvatips",browser:"1Qx5bnKAaxBEIyLPy_NchLfCLBRH99wpF",mobile:"17vl0IDbQM9Lg3qmFUcXi9varcG-LEVmo"},
					   {type:"canvabasictryout1",browser:"1R63dN787Gv8CNWFmYxre1w-anDDLBMmK",mobile:"1-0lTsbBcYlnPjMGsW6_bu3A4n6ic24jQ"},
					   {type:"canvabasictryout2",browser:"19S4WR6W-MttfhS_YviUpsnwpBBzZiXlz",mobile:"19MjP0FUiwiDmekBD9ylFKIpqX4oeTs8p"},
					   {type:"canvabasictryout3",browser:"1Z2oDKCJ9_tlyUvW6mx-_RcPIlCQhbtWc",mobile:"19kzN0vG2wwQR15hYuIKWyJs1xXGoymZe"},
					   {type:"canvabasictryout4",browser:"1SgMhIVdEeFkilquDpCirweRoZU-cEz53",mobile:"19qqV1fuHWUx3H_VXJ4497C4HPiHc8q_v"},
					   {type:"textbox",browser:"1IZy5mKXNIb7-EejNu8H06Jr1QIEdVVr6",mobile:"1Ahfcd52aHSBL23BGxKjj6dD2pguarMFf"},
					   {type:"textboxscalemove",browser:"1BqSkOMiHXP9GvTEu_Jb4oWLndsz0mI_W",mobile:"1BlqI6C41bftALCt0BLSAvY1HFpPBK5S9"},
					   {type:"textboxtryout1",browser:"1XoHMTIvR1aph3alJGEyRQIBzQ5v1XbZQ",mobile:"1C80X2AijCqwSEUApCUAyQHRJjFVUvzUM"},
					   {type:"textboxtryout2",browser:"1YLlYqe0ym0oBtJb87rzVqH3Nov9zcK0g",mobile:"1CDyg5dU7_KPS1dHXyXEnERn-O9uKYneu"},
					   {type:"textboxtryout3",browser:"1Z2oDKCJ9_tlyUvW6mx-_RcPIlCQhbtWc",mobile:"1COZApiIVn5Kopw9lkE7N4QDGxOgd3zy9"},
					   {type:"textoperation",browser:"10UauiwPfAnn3ZZV59LZh2JQ-P8cI20B1",mobile:"10VCXHk4fx42vlVds8jt7wNLTtU4GXq8r"},
					   {type:"textoperationtryout1",browser:"10s-BpzOdirsHmdix70zHYa3Gyng_hEKs",mobile:"10s-OxE8wi8QhSOp08sDiuF-Ji8RvsLSj"},
					   {type:"textoperationtryout2",browser:"11GJxZ6JC4F3QQQZxu2SVKEHGncVyKeMh",mobile:"1I48XLve3MLFiI79klqBnxZal9qgLMRuQ"},
					   {type:"mulilinesoperation",browser:"12fdhCq8tAUoamD6LJ4mWiGECRis0Zo42",mobile:"12fx1Dh7uJ04nZVQNBKKlo5I-XtQqpILa"},
					   {type:"mulilinesoperationtryout1",browser:"12iOiNzTfPJtDxVHIpB3tVnY8EqRPUnni",mobile:"12reTdxUZuJLGTKlisWYbk5-eqi86GCw1"},
					   {type:"mulilinesoperationtryout2",browser:"13RJ6bmTk4BSG024dqqH7P4-1xXEHdQsA",mobile:"13V8kfMeJe6Bn6pywr6chw1T7n_qioH4v"},
					   {type:"mulilinesoperationtryout3",browser:"13tJZVA6LAqmrqxthVPSFC7Skw8y-ANDo",mobile:"13uk1kZlxvCCB21DLzg1PPQvsouUMv9bb"},
					   {type:"mulilinesoperationtryout4",browser:"14MCdzMFxbVTqa4zgRAjkLqKQCLEopdM7",mobile:"14oXZKGrdQwPCJFkTmHDIUgeV-J-FqaqR"},
					   {type:"fontoperation",browser:"15qihV_t4xsZilUhbbiWT3aHfKqdKxHBy",mobile:"15vOHF1Z0AqXrbKcVQrOzBtQVvNqPidCc"},
					   {type:"fontbasic",browser:"161-P7geEJo6vhOWPUyocR55xLlJ5w3Th",mobile:"161UWLFggYGziJOuZnhJo4Zp84IBOLXpG"},
					   //{type:"recommendedfont",browser:"170yxJ7YSLPvUGhWq2wxnEA1HqldGd0df",mobile:"174U0vYWJnD2Wxn4nhmrb-hwvMAMuCi0D"},
					   //{type:"recommendedfont2",browser:"1NorzFGiOF7cyf5jBsUFrYkF8Y1Atuv3m",mobile:"1NppNjdJ8ceC2gvh5xMJ0UYjuSjXK_ybK"},
					   {type:"notosansjp",browser:"DAGaVcMys0g/KTg32YzfbjhEoi0uBSetQw",mobile:"1P7Y2bLlCfXB3KMbsIvbmKHve9SohAshZ"},
					   {type:"mplus",browser:"DAGbxmZz8AY/R95PBrm9DFjVyqP5F5-7zA",mobile:"1EPKDDkFJ0xThzQ2AXldVqagB0_WfOBRg"},
					   {type:"roundedmplus",browser:"DAGb99BuxMs/Vxxc3Rpx4t-0MRZY73w45A",mobile:"1T07GmR5nXKSS4XLhuoTif_kRWRfcEY6O"},
					   {type:"genjugothic",browser:"DAGcCOsEDV4/_HWCQ8Y77n5kgTgbEf3RpA",mobile:"1WMA_VC4hjSwTLCZod8sEZ6djphhQSBTA"},
					   {type:"chikushiamarugothic",browser:"DAGcGwALgZ8/ofquJwUGBl3jcBYarHg9PQ",mobile:"1XJfTAZUQq8GoDP1pNTU6NNKx73TWNV17"},
					   {type:"recommendedfontcopymethod",browser:"19JmFrWFyGjSc1H4pv4Y5SArIxbzygvOf",mobile:"19KsNg7ppfUplQdNrWwez2YCoblgoNxP6"},
					   {type:"effect",browser:"1FLXI9DMWFq1sVyJrlZmbx48cF3FyBgjj",mobile:"1FNWSuaVlIu4IuUJwK25flCUyKKH8vaUC"}
 					  ]
	useEffect(() => {
		/***
		if(routepath==="youtube"){
			setURL("https://www.youtube.com/embed/duBSyIF8io4?si=4R98B9Lg19S75t8w");
		}else if(routepath==="youtubeatm"){
		//https://learning.smarteasypos.net/video?path=youtubeatm
			setURL("https://www.youtube.com/embed/yoMsZCwr4dg?si=oWxp8t360NJmiTRm");
		***/
		if(routepath.startsWith("youtube")){
			let tlist = driveIDList.filter(list => list.type === routepath);
			if(tlist.length > 0){
				let rpath = tlist[0].browser;
				let frpath = `https://www.youtube.com/embed/${rpath}`;
				//setURL(`https://drive.google.com/file/d/${rpath}/preview`);
				setURL(frpath);
			}else{
				setErrorshow(true);
				setModalshow(false);
			}
		}else{
			let tlist = driveIDList.filter(list => list.type === routepath);
			if(tlist.length > 0){
				let rpath = isMobile ? tlist[0].mobile: tlist[0].browser;
				let frpath = rpath.startsWith("1") ? `https://drive.google.com/file/d/${rpath}/preview` : `https://www.canva.com/design/${rpath}/view?embed`;
				//setURL(`https://drive.google.com/file/d/${rpath}/preview`);
				setFontShow(!rpath.startsWith("1"));
				setURL(frpath);
			}else{
				setErrorshow(true);
				setModalshow(false);
			}
		}
		//setURL(`https://drive.google.com/file/d/${rpath}/preview?usp=drivesdk`);
		//setURL(`https://drive.google.com/uc?id=${rpath}&export=download`);
		//setURL(`https://drive.google.com/file/d/${rpath}/view?usp=drive_link`);
		//src="https://www.youtube.com/embed/mgaaVxF085E?si=lWO1QvVP8Psv6rkq"
		//src="https://www.youtube.com/embed/CY9Ck8xlUv4?si=9dF1DdyzpYQCBbb_"
		//
	},[])
    const loaded = () => {
		setModalshow(false);
    }
  return (
  			<>
		<style jsx>{`
			* {
			  margin: 0;
			  padding: 0;
			  overflow: hidden;
			  height:100vh;
			}
			iframe {
			  border:none;
			  width:100%;
			  height:100%;
			}
		`}</style>
			{!fontshow && url.length > 0 &&<div className="container">
					<Iframe url={url}
				        width="100%"
				        height="100%"
				        allowfullscreen="allowfullscreen"
				        allow="fullscreen"
				        onLoad={loaded}
				     /></div>}
			{fontshow && url.length > 0 &&<div className="container">
					<Iframe url={url}
				        width="100%"
				        height="100%"
				        allowfullscreen="allowfullscreen"
				        allow="fullscreen"
				        onLoad={loaded}
				     />
						<div className="d-grid gap-2 mt-2">
					      <Button className="noto-sans-jp-font" variant="primary" onClick={()=>{}} size="lg">
					        フォント名コピー
					      </Button>
				      </div>
				     </div>}
		     {errorshow && <h1>{routepath} Not Found</h1>}
			<ModalWaiting show={modalshow} />
			</>
  )
}
/***
					<Iframe url={url}
				        width="100%"
				        height="100%"
				        allowfullscreen="allowfullscreen"
				        allow="fullscreen"
				        onLoad={loaded}
				     />
			iframe {
			  border:none;
			  width:100%;
			  height:100%;
			}
			{url.length > 0 &&
					<Iframe url={url}
				        width="100%"
				        height="100%"
				        allowfullscreen
				        webkitallowfullscreen
				        mozallowfullscreen
				        onLoad={loaded}
				     />}
			{url.length > 0 &&
					<video src={url}
				        width="100%"
				        height="100%"
				        onLoadedData={loaded}
				     />}
***/
