import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import GetImage from "../Components/GetImage";

import "./App.css";

function FontShow() {
  const [code, setCode] = useState(GetImage("BaseFont"));
  const [code2, setCode2] = useState(GetImage("BaseFont"));
  const [params, setParams] = useState("");
  const [show, setShow] = useState(false);
  const [fontInfo,setFontInfo] = useState([]);
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(false);

	//https://learning.smarteasypos.net/fontshow?params=ChikushiAMaruGothic
/***
  const fontinfotable = [{alternate:"NotoSansJP",fontname:"Noto Sans JP",pictures:["NotoSansJP","NotoSansJP2"]},
						 {alternate:"MPlus",fontname:"M+",pictures:["MPlus"]},
						 {alternate:"RoundedMPlus",fontname:"Rounded M+",pictures:["RoundedMPlus"]},
						 {alternate:"GenjuGothic",fontname:"源柔ゴシック",pictures:["GenjuGothic","GenjuGothic2","GenjuGothic3"]},
						 {alternate:"ChikushiAMaruGothic",fontname:"筑紫A丸ゴシック",pictures:["ChikushiAMaruGothic","ChikushiAMaruGothic2"]},
						 {alternate:"MotoyaMaAporo",fontname:"モトヤアポロ",pictures:["MotoyaMaAporo","MotoyaMaAporo2"]},
						 {alternate:"TeropMincho",fontname:"テロップ明朝",pictures:["TeropMincho","TeropMincho2"]},
						 {alternate:"MochiyPopOne",fontname:"Mochiy Pop One",pictures:["MochiyPopOne","MochiyPopOne2"]}
  						];
***/
  const fontinfotable = [{alternate:"NotoSansJP",fontname:"Noto Sans JP",pictures:["Gothic"]},
						 {alternate:"MPlus",fontname:"M+",pictures:["Gothic"]},
						 {alternate:"RoundedMPlus",fontname:"Rounded M+",pictures:["Gothic"]},
						 {alternate:"GenjuGothic",fontname:"源柔ゴシック",pictures:["Gothic"]},
						 {alternate:"ChikushiAMaruGothic",fontname:"筑紫A丸ゴシック",pictures:["Gothic"]},
  						];
	const queryString = decodeURIComponent(window.location.search);
	const queries = new URLSearchParams(queryString);

	useEffect(()=>{
		let alternate = queries.get('params');
		let table = fontinfotable.filter(font => font.alternate === alternate);
		if(table.length > 0){
			setFontInfo(table[0].pictures)
			setParams(table[0].fontname);
		}else{
			setError(true);
		}
	},[]);
	/***
	useEffect(()=>{
		if(params){
			setCode(GetImage(fontInfo[0]));
			setCode2(GetImage(fontInfo[1]));
		}
	},[params]);
	***/
	const copyclipboard = () =>{
		navigator.clipboard.writeText(params);
		setShow(true)
	}
  const handleClose = () => setShow(false);
  return (
  		<>
		<style jsx>{`
			@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
			.grid-area {
			  display: grid;
			  height: 100vh;                /* grid全体の高さを設定 */
			  grid-template-rows: 70% 30%;  /* gridの割合が8:2になるよう2行用意 */
			  grid-template-columns: 100%;  /* 横幅いっぱいに1列用意 */
			}
			.top-area {
			  grid-row: 1;
			  grid-column: 1;
			  width: 100%;
			}
			.bottom-area {
			  grid-row: 2;
			  grid-column: 1;
			}
			.cardClass{
				height:80vh !important;
			}
			.noto-sans-jp-font {
			  font-family: "Noto Sans JP", serif;
			  font-optical-sizing: auto;
			  font-weight: 400;
			  font-style: normal;
			}
		`}</style>
	  <Container>
		<Row className="justify-content-md-center">
		<Col md={3}></Col>
		<Col xs={12} md={6}>
		    <Carousel activeIndex={index} controls={false}>
				{fontInfo.map((font,i) =>
			      <Carousel.Item key={i} interval={null}>
						<Image src={GetImage(font)} fluid />
			      </Carousel.Item>
			    )}
		    </Carousel>
		    {fontInfo.length > 0 && <div className="d-grid gap-2 mt-2">
		      <Button className="noto-sans-jp-font" variant="primary" onClick={copyclipboard} size="lg">
		        フォント名コピー
		      </Button>
		      {fontInfo.length > 1 && <Button className="noto-sans-jp-font" variant="primary" onClick={()=>{setIndex((index+1)%fontInfo.length)}} size="lg">
		        サンプル表示
		      </Button>}
		    </div>}
		    {error &&
		    	<h1>Not Found</h1>
		    }
		</Col>
		<Col md={3}></Col>
		</Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="noto-sans-jp-font">コピー終了</Modal.Title>
        </Modal.Header>
        <Modal.Body className="noto-sans-jp-font">"{params}"をコピーしました。</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className="noto-sans-jp-font">
             OK
          </Button>
        </Modal.Footer>
      </Modal>
      </>
  );
}

export default FontShow;

/****
		      <Carousel.Item interval={null}>
					<Image src={code} fluid />
		      </Carousel.Item>
		      <Carousel.Item interval={null}>
					<Image src={code2} fluid />
		      </Carousel.Item>
			<div className="grid-area">
				<div className="top-area">
					<Image src={code} fluid />
			    </div>
			    <div className="bottom-area mt-2">
				    <div className="d-grid gap-2">
				      <Button variant="primary" size="lg">
				        Block level button
				      </Button>
				      <Button variant="secondary" size="lg">
				        Block level button
				      </Button>
				    </div>
				</div>
		    </div>
***/

